import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { Avatar, Col, Divider, Row, Typography } from 'antd'
import { filter, isEmpty, isNil, map } from 'lodash'
import mime from 'mime-types'
import moment from 'moment'
import numeral from 'numeral'
import http from '../../utils/api'
import { formatCurrency } from '../../utils/helpers'
import AddressModal from '../CompanyEditModals/AddressModal'
import DescriptionModal from '../CompanyEditModals/DescriptionModal'
import AlternativeNamesModal from '../CompanyEditModals/AlternativeNamesModal'
import DetailsModal from '../CompanyEditModals/DetailsModal'
import GeneralInfoModal from '../CompanyEditModals/GeneralInfoModal'
import ServiceProviderDynamicFieldsModal from '../CompanyEditModals/ServiceProviderDynamicFieldsModal'
import EditButton from '../EditButton/EditButton'
import ServiceProviderClients from '../ServiceProviderPeople/ServiceProviderClients'
import ServiceProviderContent from '../ServiceProviderPeople/ServiceProviderContent'
import '../DiscoverServiceProviderDetailsModal/DiscoverServiceProviderProfile.less'
import { TIER_PRO } from '../../constants/types/tiers'

const { Title } = Typography

const getEndPoint = (category) => {
  switch (category) {
    case 'fund':
      return 'fundmanagerdetails'
    case 'serviceprovider':
      return 'serviceproviderdetails'
    case 'media':
      return 'mediadetails'
    case 'allocator':
      return 'allocatordetails'
    case 'cfn':
      return 'cfndetails'
    default:
      return ''
  }
}

const CompanyOverview = ({ category, companyId, changeCompanyName }) => {
  const noEditState = {
    generalInfo: false,
    address: false,
    description: false,
    companyDetails: false,
    familyBio: false,
  }

  const [buttonVisible, setButtonVisible] = useState(noEditState)
  const [currentModal, setCurrentModal] = useState(null)
  const [company, setCompany] = useState({})

  const [questions, setQuestions] = useState([])
  const [questionTypes, setQuestionTypes] = useState([])
  const [answers, setAnswers] = useState([])

  const [previewObject, setPreviewObject] = useState({})

  const [cfnCurrentStep, setCfnCurrentStep] = useState(0)

  const [cfnBio, setCfnBio] = useState(null)
  const [cfnInvestments, setCfnInvestments] = useState(null)
  const [cfnLeadership, setCfnLeadership] = useState(null)
  const [cfnVisibility, setCfnVisibility] = useState(null)

  const questionsWithOptions = new Set(
    map(
      filter(questionTypes, (qt) => qt.answers),
      (qt) => qt.questionTypeId
    )
  )

  const toggleEdit = (section, value) => {
    const newState = { ...noEditState }
    newState[section] = value
    setButtonVisible(newState)
  }

  const cfnAnswerMapper = (answers, section) => {
    if (section === 'cfnVisibility') {
      return answers.map((row) => (
        <>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="paddedRow"
          >
            <Col span={12}>
              <div>{row.label}: </div>
            </Col>
            <Col span={12}>
              <div className="boldText">
                {row.value === true ? 'Yes' : 'No'}
              </div>
            </Col>
          </Row>
        </>
      ))
    } else {
      return answers.map((row) => (
        <>
          {checkEmpty(row.answer) != 'N/A' && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>{row.label}: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {typeof row.answer === 'string' ? (
                    row.answer.charAt(0) === ',' ? (
                      row.answer.substring(1)
                    ) : (
                      row.answer
                    )
                  ) : typeof row.answer === 'number' ? (
                    row.answer
                  ) : row.answer.type === 'file' ? (
                    <a href={row.answer.content} target="_blank">
                      {row.answer.content}
                    </a>
                  ) : row.answer instanceof Array === true ? (
                    row.answer.map((e) => e.name).join(', ')
                  ) : (
                    row.answer.content
                  )}
                </div>
              </Col>
            </Row>
          )}
        </>
      ))
    }
  }

  useEffect(() => {
    if (checkEmpty(company.name) == 'N/A') {
      let type = ''

      type = getEndPoint(category)

      http
        .get(`/companies/${companyId}/${type}`)
        .then(function (response) {
          setCompany(response.data)
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })

      if (type === 'serviceproviderdetails') {
        http
          .get('/serviceproviders/questions')
          .then(function (response) {
            setQuestions(response.data)
          })
          .catch(function (error) {
            console.log(error)
          })
        if (!isNil(companyId)) {
          http
            .get(`/serviceproviders/0/${companyId}/answers`)
            .then(function (response) {
              setAnswers(response.data.answers)
              const { answers } = response.data
              const peopleAnswer = answers.find(
                (answer) => answer.shortName === 'People'
              )

              const testimonialsAnswer = answers.find(
                (answer) => answer.shortName === 'Testimonials'
              )

              const clientsAnswer = answers.find(
                (answer) => answer.shortName === 'Clients'
              )

              const blogLinkAnswer = answers.find(
                (answer) => answer.shortName === 'BlogLink'
              )
              const caseStudiesLinkAnswer = answers.find(
                (answer) => answer.shortName === 'CaseStudiesLink'
              )

              const webCastsLinkAnswer = answers.find(
                (answer) => answer.shortName === 'WebCastsLink'
              )

              const whitePaperGuidesLinkAnswer = answers.find(
                (answer) => answer.shortName === 'WhitePaperGuidesLink'
              )

              const facebookPageLinkAnswer = answers.find(
                (answer) => answer.shortName === 'FacebookPageLink'
              )
              const twitterProfileLink = answers.find(
                (answer) => answer.shortName === 'TwitterProfileLink'
              )

              const linkedInPageLink = answers.find(
                (answer) => answer.shortName === 'LinkedInPageLink'
              )

              const instagramProfileLink = answers.find(
                (answer) => answer.shortName === 'InstagramProfileLink'
              )

              const logoLink = answers.find(
                (answer) => answer.shortName === 'Logo'
              )

              const companyMediaLink = answers.find(
                (answer) => answer.shortName === 'CompanyMedia'
              )

              const parsedAnswers = (answers) =>
                isNil(answers) || isNil(answers.responseText)
                  ? null
                  : JSON.parse(answers.responseText)

              const imagesList = parsedAnswers(companyMediaLink)?.filter(
                (link) => !mime.lookup(link).includes('video')
              )

              const videoLink = parsedAnswers(companyMediaLink)?.filter(
                (link) => mime.lookup(link).includes('video')
              )
              const object = {
                people: parsedAnswers(peopleAnswer),
                testimonials: parsedAnswers(testimonialsAnswer),
                clients: parsedAnswers(clientsAnswer),
                blogUrl: blogLinkAnswer?.responseText,
                caseStudiesUrl: caseStudiesLinkAnswer?.responseText,
                webCastsUrl: webCastsLinkAnswer?.responseText,
                whitepapersAndGuidesUrl:
                  whitePaperGuidesLinkAnswer?.responseText,
                facebookUrl: facebookPageLinkAnswer?.responseText,
                twitterUrl: twitterProfileLink?.responseText,
                linkedInUrl: linkedInPageLink?.responseText,
                instagramUrl: instagramProfileLink?.responseText,
                imageUrl: logoLink?.responseText,
                images: imagesList,
                videoUrl: videoLink,
                companyName: company[0]?.name,
                address: company[0]?.address,
                city: company[0]?.city,
                description: company[0]?.companyDescription,
                website: company[0]?.companyWebsite,
              }
              setPreviewObject(object)
            })
            .catch(function (error) {
              console.log(error)
            })
        }
        http
          .get('/questions/types')
          .then(function (response) {
            setQuestionTypes(response.data)
          })
          .catch(function (error) {
            console.log(error)
          })
      }

      if (type === 'cfndetails') {
        http
          .get(`/companies/questions/cfn/2/${companyId}`)
          .then((response) => {
            setCfnBio(response.data)
          })
          .catch((error) => {
            console.log(error)
          })
        http
          .get(`/companies/questions/cfn/3/${companyId}`)
          .then((response) => {
            setCfnInvestments(response.data)
          })
          .catch((error) => {
            console.log(error)
          })
        http
          .get(`/companies/questions/cfn/5/${companyId}`)
          .then((response) => {
            setCfnLeadership(response.data)
          })
          .catch((error) => {
            console.log(error)
          })
        http
          .get(`/companies/questions/cfn/visibility/company/${companyId}`)
          .then((response) => {
            setCfnVisibility(response.data)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }, [category, company, companyId, currentModal, cfnBio, cfnInvestments])

  const details = (company) => {
    if (company.companyAllocator) {
      return (
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{
            background: 'white',
            marginTop: '24px',
            borderRadius: '5px',
          }}
          onMouseEnter={() => toggleEdit('companyDetails', true)}
          onMouseLeave={() => toggleEdit('companyDetails', false)}
        >
          <Col span={24}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={12}>
                <Title level={4} className="header">
                  Company Details
                </Title>
              </Col>
              <Col span={12}>
                <div
                  style={{ textAlign: 'right' }}
                  hidden={!buttonVisible.companyDetails}
                >
                  <EditButton onClick={() => setCurrentModal('details')} />
                </div>
              </Col>
            </Row>
            <Divider />
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>Primary Investor Category: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {checkEmpty(company.companyAllocator.primaryInvestorCategory)}
                </div>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>Secondary Investor Category: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {checkEmpty(
                    company.companyAllocator.secondaryInvestorCategory
                  )}
                </div>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>Portfolio Size: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {checkEmpty(
                    formatCurrency(company.companyAllocator.portfolioSize)
                  )}
                </div>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>Fund Domicile Interest: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {checkEmpty(company.companyAllocator.fundDomicileInterest)}
                </div>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>Investment Vehicle: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {checkEmpty(company.companyAllocator.investmentVehicle)}
                </div>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>First Loss Capital: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {company.companyAllocator.firstLossCapital ? 'Yes' : 'No'}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    } else if (company.companyManager) {
      return (
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{
            background: 'white',
            marginTop: '24px',
            borderRadius: '5px',
          }}
          onMouseEnter={() => toggleEdit('companyDetails', true)}
          onMouseLeave={() => toggleEdit('companyDetails', false)}
        >
          <Col span={24}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={12}>
                <Title level={4} className="header">
                  Company Details
                </Title>
              </Col>
              <Col span={12}>
                <div
                  style={{ textAlign: 'right' }}
                  hidden={!buttonVisible.companyDetails}
                >
                  <EditButton onClick={() => setCurrentModal('details')} />
                </div>
              </Col>
            </Row>
            <Divider />
            <Row type="flex" justify="space-between" align="top">
              <Col span={4}>
                <div>Firm AUM: </div>
              </Col>
              <Col span={8}>
                <div className="boldText">
                  {company.companyManager.firmAum
                    ? numeral(company.companyManager.firmAum).format('0,0[.]00')
                    : 'N/A'}
                </div>
              </Col>
              <Col span={4}>
                <div>CIK: </div>
              </Col>
              <Col span={8}>
                <div className="boldText">
                  {checkEmpty(company.companyManager.cik)}
                </div>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="top"
              className="paddedRow"
            >
              <Col span={4}>
                <div>CRD: </div>
              </Col>
              <Col span={8}>
                <div className="boldText">
                  {checkEmpty(company.companyManager.crd)}
                </div>
              </Col>
              <Col span={4}>
                <div>Fund Attributes: </div>
              </Col>
              <Col span={8}>
                <div className="boldText">
                  {checkEmpty(company.companyManager.fundAttributes)}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    } else if (company.companyServiceProvider) {
      return (
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{
            background: 'white',
            marginTop: '24px',
            borderRadius: '5px',
          }}
          onMouseEnter={() => toggleEdit('companyDetails', true)}
          onMouseLeave={() => toggleEdit('companyDetails', false)}
        >
          <Col span={24}>
            <Row type="flex" justify="space-between" align="middle">
              <Col span={12}>
                <Title level={4} className="header">
                  Company Details
                </Title>
              </Col>
              <Col span={12}>
                <div
                  style={{ textAlign: 'right' }}
                  hidden={!buttonVisible.companyDetails}
                >
                  <EditButton onClick={() => setCurrentModal('details')} />
                </div>
              </Col>
            </Row>
            <Divider />
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>Service Provider Category: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {checkEmpty(
                    company.companyServiceProvider.serviceProviderCategory
                  )}
                </div>
              </Col>
            </Row>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="paddedRow"
            >
              <Col span={12}>
                <div>Can Send Meeting Request: </div>
              </Col>
              <Col span={12}>
                <div className="boldText">
                  {company.canSendMeetingRequest == true ? 'Yes' : 'No'}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    } else if (company.companyCFN) {
      return (
        <>
          {cfnBio && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{
                background: 'white',
                marginTop: '24px',
                borderRadius: '5px',
              }}
              onMouseEnter={() => toggleEdit('familyBio', true)}
              onMouseLeave={() => toggleEdit('familyBio', false)}
            >
              <Col span={24}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={12}>
                    <Title level={4} className="header">
                      About
                    </Title>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ textAlign: 'right' }}
                      hidden={!buttonVisible.familyBio}
                    >
                      <EditButton
                        onClick={() => {
                          setCfnCurrentStep(2)
                          setCurrentModal('details')
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Divider />
                {cfnAnswerMapper(cfnBio, 'familyBio')}
              </Col>
            </Row>
          )}

          {cfnInvestments && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{
                background: 'white',
                marginTop: '24px',
                borderRadius: '5px',
              }}
              onMouseEnter={() => toggleEdit('cfnInvestments', true)}
              onMouseLeave={() => toggleEdit('cfnInvestments', false)}
            >
              <Col span={24}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={12}>
                    <Title level={4} className="header">
                      Investments
                    </Title>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ textAlign: 'right' }}
                      hidden={!buttonVisible.cfnInvestments}
                    >
                      <EditButton
                        onClick={() => {
                          setCfnCurrentStep(3)
                          setCurrentModal('details')
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Divider />
                {cfnAnswerMapper(cfnInvestments, 'cfnInvestments')}
              </Col>
            </Row>
          )}

          {cfnLeadership && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{
                background: 'white',
                marginTop: '24px',
                borderRadius: '5px',
              }}
              onMouseEnter={() => toggleEdit('cfnLeadership', true)}
              onMouseLeave={() => toggleEdit('cfnLeadership', false)}
            >
              <Col span={24}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={12}>
                    <Title level={4} className="header">
                      Leadership
                    </Title>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ textAlign: 'right' }}
                      hidden={!buttonVisible.cfnLeadership}
                    >
                      <EditButton
                        onClick={() => {
                          setCfnCurrentStep(5)
                          setCurrentModal('details')
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Divider />
                {cfnAnswerMapper(cfnLeadership, 'cfnLeadership')}
              </Col>
            </Row>
          )}

          {cfnVisibility && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{
                background: 'white',
                marginTop: '24px',
                borderRadius: '5px',
              }}
              onMouseEnter={() => toggleEdit('cfnVisibility', true)}
              onMouseLeave={() => toggleEdit('cfnVisibility', false)}
            >
              <Col span={24}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={12}>
                    <Title level={4} className="header">
                      Visibility
                    </Title>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ textAlign: 'right' }}
                      hidden={!buttonVisible.cfnVisibility}
                    >
                      <EditButton
                        onClick={() => {
                          setCfnCurrentStep(5)
                          setCurrentModal('cfnVisibility')
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <Divider />
                {cfnAnswerMapper(cfnVisibility, 'cfnVisibility')}
              </Col>
            </Row>
          )}
        </>
      )
    }

    return null
  }

  const getResponseText = (questionId, isLink) => {
    const response = answers.find(
      (a) => a.serviceProviderQuestionId == questionId
    )

    if (response) {
      if (isLink && response.responseText != null) {
        if (
          response.responseText.includes('http') ||
          response.responseText.includes('https')
        ) {
          return response.responseText
        } else {
          return `http://${response.responseText}`
        }
      }
      return response.responseText
    }
  }

  return (
    <div className="CompanyDetails-Wrapper">
      <Row type="flex" align="top" gutter={16}>
        <Col span={12}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ background: 'white', borderRadius: '5px' }}
            onMouseEnter={() => toggleEdit('generalInfo', true)}
            onMouseLeave={() => toggleEdit('generalInfo', false)}
          >
            <Col span={24}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={12}>
                  <Title level={4} className="header">
                    General Information
                  </Title>
                </Col>
                <Col span={12}>
                  <div
                    style={{ textAlign: 'right' }}
                    hidden={!buttonVisible.generalInfo}
                  >
                    <EditButton onClick={() => setCurrentModal('general')} />
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Name: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">{checkEmpty(company.name)}</div>
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>SF Account ID: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">
                    {isEmpty(company.sfAccountId) ? (
                      'N/A'
                    ) : (
                      <a
                        href={`https://contextcapitalpartners.lightning.force.com/lightning/r/Account/${company.sfAccountId}/view`}
                        target="_blank"
                      >
                        {company.sfAccountId}
                      </a>
                    )}
                  </div>
                </Col>
              </Row>

              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Tier: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">{checkEmpty(company.tierName)}</div>
                </Col>
              </Row>

              {company.tierId===TIER_PRO ? (
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Pro Expiring At: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">
                  {company.proExpiringAt!==null ? (
                    moment(company.proExpiringAt).format('MMMM D, YYYY')
                  ):('N/A')
                }
                    </div>
                </Col>
              </Row>):(<></>)}

              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Date of Creation: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">
                    {checkEmpty(
                      moment(company.createdAt).format('MMMM D, YYYY LT')
                    )}
                  </div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Last Updated: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">
                    {checkEmpty(
                      moment(company.updatedAt).format('MMMM D, YYYY LT')
                    )}
                  </div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Website: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">
                    {checkEmpty(company.companyWebsite)}
                  </div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Phone Number: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">
                    {checkEmpty(company.phoneNumber)}
                  </div>
                </Col>
              </Row>
              {category !== 'serviceprovider' && (
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="paddedRow"
                >
                  <Col span={12}>
                    <div>Logo: </div>
                  </Col>
                  <Col span={12}>
                    <img width="125" src={company.imageURL} />
                  </Col>
                </Row>
              )}

              {category === 'serviceprovider' &&
                questions.length > 0 &&
                answers.length > 0 &&
                questions
                  .filter((q) => q.stepNumber == 2)
                  .map((question, key) => (
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="paddedRow"
                      key={key}
                    >
                      <Col span={12}>
                        <div>{question.shortName}:</div>
                      </Col>
                      <Col span={12}>
                        <div className="boldText">
                          <img
                            width="125"
                            src={getResponseText(
                              question.serviceProviderQuestionId
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  ))}
            </Col>
          </Row>

          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              background: 'white',
              marginTop: '24px',
              borderRadius: '5px',
            }}
            onMouseEnter={() => toggleEdit('address', true)}
            onMouseLeave={() => toggleEdit('address', false)}
          >
            <Col span={24}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={12}>
                  <Title level={4} className="header">
                    Contact
                  </Title>
                </Col>
                <Col span={12}>
                  <div
                    style={{ textAlign: 'right' }}
                    hidden={!buttonVisible.address}
                  >
                    <EditButton onClick={() => setCurrentModal('address')} />
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Continent: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">
                    {checkEmpty(company.continent)}
                  </div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Country: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">{checkEmpty(company.country)}</div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>State: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">{checkEmpty(company.state)}</div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>City: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">{checkEmpty(company.city)}</div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Postal Code: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">
                    {checkEmpty(company.postalCode)}
                  </div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Address 1: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">{checkEmpty(company.address1)}</div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Address 2: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">{checkEmpty(company.address2)}</div>
                </Col>
              </Row>
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <Col span={12}>
                  <div>Address 3: </div>
                </Col>
                <Col span={12}>
                  <div className="boldText">{checkEmpty(company.address3)}</div>
                </Col>
              </Row>
              {category === 'serviceprovider' &&
                questions.length > 0 &&
                answers.length > 0 &&
                questions
                  .filter((q) => q.stepNumber == 7)
                  .map((question, key) => (
                    <Row
                      type="flex"
                      justify="space-between"
                      align="middle"
                      className="paddedRow"
                      key={key}
                    >
                      <Col span={12}>
                        <div>{question.text}:</div>
                      </Col>
                      <Col span={12}>
                        <div className="boldText">
                          <div className="boldText">
                            <a
                              href={getResponseText(
                                question.serviceProviderQuestionId,
                                true
                              )}
                            >
                              {getResponseText(
                                question.serviceProviderQuestionId
                              )}
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
            </Col>
          </Row>

          {category === 'serviceprovider' && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{
                background: 'white',
                marginTop: '24px',
                borderRadius: '5px',
              }}
              onMouseEnter={() => toggleEdit('clients', true)}
              onMouseLeave={() => toggleEdit('clients', false)}
            >
              <Col span={24}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={12}>
                    <Title level={4} className="header">
                      Clients
                    </Title>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ textAlign: 'right' }}
                      hidden={!buttonVisible.clients}
                    >
                      <EditButton onClick={() => setCurrentModal('clients')} />
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="paddedRow"
                >
                  <Col span={24}>
                    <ServiceProviderClients
                      serviceProviders={previewObject.clients}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>

        <Col span={12}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ background: 'white', borderRadius: '5px' }}
            onMouseEnter={() => toggleEdit('description', true)}
            onMouseLeave={() => toggleEdit('description', false)}
          >
            <Col span={24}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={12}>
                  <Title level={4} className="header">
                    Description
                  </Title>
                </Col>
                <Col span={12}>
                  <div
                    style={{ textAlign: 'right' }}
                    hidden={!buttonVisible.description}
                  >
                    <EditButton
                      onClick={() => setCurrentModal('description')}
                    />
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <div className="boldText">
                  {checkEmpty(company.companyDescription)}
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{
              background: 'white',
              marginTop: '24px',
              borderRadius: '5px',
            }}
            onMouseEnter={() => toggleEdit('alternativeNames', true)}
            onMouseLeave={() => toggleEdit('alternativeNames', false)}
          >
            <Col span={24}>
              <Row type="flex" justify="space-between" align="middle">
                <Col span={12}>
                  <Title level={4} className="header">
                    Alternative Names
                  </Title>
                </Col>
                <Col span={12}>
                  <div
                    style={{ textAlign: 'right' }}
                    hidden={!buttonVisible.alternativeNames}
                  >
                    <EditButton
                      onClick={() => setCurrentModal('alternativeNames')}
                    />
                  </div>
                </Col>
              </Row>
              <Divider />
              <Row
                type="flex"
                justify="space-between"
                align="middle"
                className="paddedRow"
              >
                <div className="boldText">
                  {checkEmpty(company.alternativeNames)}
                </div>
              </Row>
            </Col>
          </Row>

          {category === 'serviceprovider' && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{
                background: 'white',
                marginTop: '24px',
                borderRadius: '5px',
              }}
              onMouseEnter={() => toggleEdit('media', true)}
              onMouseLeave={() => toggleEdit('media', false)}
            >
              <Col span={24}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={12}>
                    <Title level={4} className="header">
                      Media
                    </Title>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ textAlign: 'right' }}
                      hidden={!buttonVisible.media}
                    >
                      <EditButton onClick={() => setCurrentModal('media')} />
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="paddedRow"
                >
                  <Col span={24}>
                    <Row className="media-row">
                      {!isNil(previewObject.videoUrl) &&
                        !isEmpty(previewObject.videoUrl) && (
                          <Col span={24}>
                            {' '}
                            <>
                              <div className="video-column">
                                <video
                                  autoPlay={false}
                                  width={'100%'}
                                  muted={false}
                                  style={{
                                    borderRadius: '8px',
                                    boxShadow:
                                      '3px 3px 10px rgba(186, 186, 201, 0.15)',
                                  }}
                                  controls
                                >
                                  <source src={previewObject.videoUrl} />
                                </video>
                              </div>
                            </>
                          </Col>
                        )}
                      {!isEmpty(previewObject.images) &&
                        previewObject.images && (
                          <Col span={24}>
                            <>
                              <Row
                                style={{
                                  height: '100%',
                                }}
                              >
                                {previewObject.images.map((image, id) => (
                                  <Col
                                    key={id}
                                    className="service-image-col"
                                    span={
                                      ((previewObject.images.length === 1 ||
                                        (id === 2 &&
                                          previewObject.images.length === 3)) &&
                                        12) ||
                                      6
                                    }
                                  >
                                    <img
                                      src={image}
                                      className="service-image"
                                    />
                                  </Col>
                                ))}
                              </Row>
                            </>
                          </Col>
                        )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {category === 'serviceprovider' && (
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              style={{
                background: 'white',
                marginTop: '24px',
                borderRadius: '5px',
              }}
              onMouseEnter={() => toggleEdit('content', true)}
              onMouseLeave={() => toggleEdit('content', false)}
            >
              <Col span={24}>
                <Row type="flex" justify="space-between" align="middle">
                  <Col span={12}>
                    <Title level={4} className="header">
                      Content
                    </Title>
                  </Col>
                  <Col span={12}>
                    <div
                      style={{ textAlign: 'right' }}
                      hidden={!buttonVisible.content}
                    >
                      <EditButton onClick={() => setCurrentModal('content')} />
                    </div>
                  </Col>
                </Row>
                <Divider />
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  className="paddedRow"
                >
                  <Col span={24}>
                    <ServiceProviderContent serviceProviders={previewObject} />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {details(company)}
        </Col>
      </Row>

      {company.createdAt && currentModal === 'general' && (
        <GeneralInfoModal
          visible={currentModal === 'general'}
          company={company}
          companyId={companyId}
          changeCompanyName={changeCompanyName}
          handleCancel={() => setCurrentModal(null)}
          handleOk={() => {
            setCurrentModal(null)
            setCompany({})
          }}
          questions={questions}
          answers={answers}
          questionsWithOptions={questionsWithOptions}
          category={category}
        />
      )}
      {company.createdAt && currentModal === 'description' && (
        <DescriptionModal
          visible={currentModal === 'description'}
          company={company}
          companyId={companyId}
          handleCancel={() => setCurrentModal(null)}
          handleOk={() => {
            setCurrentModal(null)
            setCompany({})
          }}
        />
      )}
      {company.createdAt && currentModal === 'alternativeNames' && (
        <AlternativeNamesModal
          visible={currentModal === 'alternativeNames'}
          company={company}
          companyId={companyId}
          handleCancel={() => setCurrentModal(null)}
          handleOk={() => {
            setCurrentModal(null)
            setCompany({})
          }}
        />
      )}
      {company.createdAt && currentModal === 'address' && (
        <AddressModal
          visible={currentModal === 'address'}
          company={company}
          companyId={companyId}
          handleCancel={() => setCurrentModal(null)}
          handleOk={() => {
            setCurrentModal(null)
            setCompany({})
          }}
          questions={questions}
          answers={answers}
          questionsWithOptions={questionsWithOptions}
          category={category}
        />
      )}
      {company.createdAt && currentModal === 'details' && (
        <DetailsModal
          visible={currentModal === 'details'}
          company={company}
          companyId={companyId}
          handleCancel={() => setCurrentModal(null)}
          handleOk={() => {
            setCurrentModal(null)
            if (category == 'cfn') {
              setCompany({})
            }
          }}
          stepNumber={cfnCurrentStep}
          visibility={false}
        />
      )}
      {company.createdAt && currentModal === 'cfnVisibility' && (
        <DetailsModal
          visible={currentModal === 'cfnVisibility'}
          company={company}
          companyId={companyId}
          handleCancel={() => setCurrentModal(null)}
          handleOk={() => {
            setCurrentModal(null)
            if (category == 'cfn') {
              setCompany({})
            }
          }}
          stepNumber={cfnCurrentStep}
          visibility={currentModal === 'cfnVisibility' ? true : false}
          visibilityQuestions={cfnVisibility}
        />
      )}
      {company.createdAt && currentModal === 'familyBio' && (
        <DescriptionModal
          visible={currentModal === 'familyBio'}
          company={company}
          companyId={companyId}
          handleCancel={() => setCurrentModal(null)}
          handleOk={() => setCurrentModal(null)}
          fieldName={'familyBio'}
        />
      )}
      {company.createdAt &&
        (currentModal === 'media' ||
          currentModal === 'clients' ||
          currentModal === 'content') && (
          <ServiceProviderDynamicFieldsModal
            visible={currentModal === currentModal}
            company={company}
            companyId={companyId}
            handleCancel={() => setCurrentModal(null)}
            handleOk={() => {
              setCurrentModal(null)
              setCompany({})
            }}
            questions={questions}
            answers={answers}
            questionsWithOptions={questionsWithOptions}
            section={currentModal}
          />
        )}
    </div>
  )
}

const checkEmpty = (value) => {
  if (!value) return 'N/A'
  else return value
}

CompanyOverview.propTypes = {
  companyId: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
}

export default CompanyOverview
